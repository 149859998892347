import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resposta } from "@app/comum/modelo/resposta";
import { TipoArquivoUrna } from "@app/comum/modelo/tipo-arquivo-urna";
import { environment } from "@env/environment";
import moment from "moment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TransmissaoService {
  baseUrlArquivos = environment.baseUrl + "arquivo-urna/";

  constructor(private http: HttpClient) {}

  filtrarArquivos(
    selecionados: FileList,
    somenteArquivosComErro: boolean,
    nomesArquivosComErro: string[]
  ): Map<string, File[]> {
    let arquivosPorSecao: Map<string, File[]> = new Map();
    let arquivos = Array.from(selecionados);

    for (let k = 0; k < arquivos.length; k++) {
      let arquivo = arquivos[k];
      if (
        this.verificarTransmissaoSomenteComErro(
          arquivo.name,
          somenteArquivosComErro,
          nomesArquivosComErro
        )
      ) {
        let nomeSemSufixo = this.extrairNomeArquivoSemSufixo(arquivo.name);
        if (arquivosPorSecao.has(nomeSemSufixo)) {
          let dataTransfer: File[] = arquivosPorSecao.get(nomeSemSufixo);
          dataTransfer.push(arquivo);
        } else {
          let dataTransfer: File[] = [];
          dataTransfer.push(arquivo);
          arquivosPorSecao.set(nomeSemSufixo, dataTransfer);
        }
      }
    }
    return arquivosPorSecao;
  }

  private verificarTransmissaoSomenteComErro(
    nome: string,
    somenteArquivosComErro?: boolean,
    nomesArquivosComErro?: string[]
  ): boolean {
    if (
      !somenteArquivosComErro ||
      !nomesArquivosComErro ||
      nomesArquivosComErro.length === 0
    ) {
      return true; //significa que eh uma transmissao normal
    }

    for (let n of nomesArquivosComErro) {
      if (
        n === nome ||
        (this.extrairNomeArquivoSemExtensao(nome) ===
          this.extrairNomeArquivoSemExtensao(n) &&
          this.isArquivoAssinatura(nome))
      ) {
        return true;
      }
    }

    return false;
  }

  private extrairNomeArquivoSemSufixo(nome: string) {
    return nome.split("-")[0];
  }

  private extrairNomeArquivoSemExtensao(nome: string) {
    return nome.split(".")[0];
  }

  private extrairExtensaoArquivo(nome: string) {
    return nome.split(".")[1];
  }

  public isArquivoAssinatura(nomeArquivo: string): boolean {
    let extensoesAssinaturas = TipoArquivoUrna.sufixoAssinaturas();
    return (
      nomeArquivo &&
      extensoesAssinaturas.includes(this.extrairExtensaoArquivo(nomeArquivo))
    );
  }

  transmitirArquivos(data: File[]): Observable<Resposta> {
    const formData = new FormData();

    Array.from(data).forEach((file) => {
      formData.append("arquivo", file);
    });

    formData.append(
      "timestampInicioTransmissao",
      moment().toDate().getTime() + ""
    );

    return this.http.post<Resposta>(
      this.baseUrlArquivos + "transmitir",
      formData
    );
  }
}
