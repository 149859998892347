import { NgFor } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';

@Component({
  selector: 'app-apresentacao-erro-dialog',
  standalone: true,
  imports: [NgFor, MatDialogActions, MatDialogContent, MatDialogTitle, MatDialogClose, MatButtonModule],
  templateUrl: './apresentacao-erro-dialog.component.html',
  styleUrl: './apresentacao-erro-dialog.component.css'
})
export class ApresentacaoErroDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

}
