import { KeycloakOptions } from "keycloak-angular";

let keycloakOptions: KeycloakOptions = {
  config: {
    url: "https://autentica-hmg.tse.jus.br/auth/",
    realm: "eleitor-codigo-auth",
    clientId: 'hermes-frontend'
  },
  initOptions: {
    onLoad: 'login-required',
    checkLoginIframe: false,
  },
  enableBearerInterceptor: true
}

export const environment = {
  production: false,
  appVersion: require('../../package.json').version,
  tamanhoLoteEnvio: 5, //Nao mexer
  quantidadeRequisicoesParalelas: 6,
  timeoutEnvioArquivosMilissegundos: 120000,
  tempoEsperaEntreRequisicoesMilissegundos: 10,
  quantidadeTentativasEnvio: 5,
  baseUrl: '/hermes/api/',
  keycloak: keycloakOptions
  //parametro que representa a sessao de usuario
};

