import {ArquivoUrna} from "@comum/interfaces/arquivo-urna";

export class TipoArquivoUrna {

  static readonly BU: ArquivoUrna = {
    tipo: "bu",
    extensao: ".dat",
    sufixo: "bu",
    descricao: "Boletim de Urna",
  };
  static readonly BU_SA: ArquivoUrna = {
    tipo: "busa",
    extensao: ".dat",
    sufixo: "busa",
    descricao: "Boletim de Urna (SA)",
  };
  static readonly BU_IMPRESSO: ArquivoUrna = {
    tipo: "imgbu",
    extensao: ".dat",
    sufixo: "imgbu",
    descricao: "Imagem de BU",
  };
  static readonly BU_IMPRESSO_SA: ArquivoUrna = {
    tipo: "imgbusa",
    extensao: ".dat",
    sufixo: "imgbusa",
    descricao: "Imagem de BU (SA)",
  };
  static readonly RESULTADO_URNA_CADASTRO: ArquivoUrna = {
    tipo: "jufa",
    extensao: ".dat",
    sufixo: "jufa",
    descricao: "Resultado do Cadastro",
  };
  static readonly LOG_JEZ: ArquivoUrna = {
    tipo: "logjez",
    extensao: ".jez",
    sufixo: "log",
    descricao: "Log",
  };
  static readonly LOG_SA_JEZ: ArquivoUrna = {
    tipo: "logsajez",
    extensao: ".jez",
    sufixo: "logsa",
    descricao: "Log (SA)",
  };
  static readonly RDV: ArquivoUrna = {
    tipo: "rdv",
    extensao: ".dat",
    sufixo: "rdv",
    descricao: "Registro Digital do Voto",
  };
  static readonly RDV_RED_SA: ArquivoUrna = {
    tipo: "rdvred",
    extensao: ".dat",
    sufixo: "rdvred",
    descricao: "RDV(RDV para o SA(pelo RED)",
  };
  static readonly ASSINATURA: ArquivoUrna = {
    tipo: "vscmr",
    extensao: ".vsc",
    sufixo: "vota",
    descricao: "Assinatura",
  };
  static readonly ASSINATURA_SA: ArquivoUrna = {
    tipo: "vscsa",
    extensao: ".vsc",
    sufixo: "sa",
    descricao: "Assinatura (SA)",
  };
  static readonly ASSINATURA_SA_GERADA_RED: ArquivoUrna = {
    tipo: "vscred",
    extensao: ".vsc",
    sufixo: "red",
    descricao: "Assinatura (SA Gerada Pelo RED)",
  };
  static readonly HASHES: ArquivoUrna = {
    tipo: "hash",
    extensao: ".dat",
    sufixo: "hash",
    descricao: "Hashes dos arquivos da urna",
  };
  static readonly VERSAO_CONTRATO_ASN1: ArquivoUrna = {
    tipo: "ver",
    extensao: ".ver",
    sufixo: "mr",
    descricao: "Versão do contrato ASN.1",
  };
  static readonly CARIMBO_TEMPO: ArquivoUrna = {
    tipo: "info",
    extensao: ".dat",
    sufixo: "info",
    descricao: "Carimbo de tempo",
  }

  static sufixoAssinaturas(): string[] {
    return Array.of(this.ASSINATURA.sufixo,
      this.ASSINATURA_SA.sufixo,
      this.ASSINATURA_SA_GERADA_RED.sufixo
    );
  }

  static expressaoRegularArquivos(): string {
    let tipos = new Set(Object.values(this).map((obj) => `${obj.sufixo}${obj.extensao}`));
    return '('.concat(Array.from(tipos).join('|')).concat(')');
  }

  static getTiposArquivosComuns() {
    return [
      this.BU,
      this.BU_IMPRESSO,
      this.RESULTADO_URNA_CADASTRO,
      this.LOG_JEZ,
      this.RDV,
      this.ASSINATURA,
      this.HASHES,
      this.VERSAO_CONTRATO_ASN1,
      this.CARIMBO_TEMPO
    ];
  }
}
