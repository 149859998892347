<p>
    <mat-toolbar class="menu px-0 px-sm-2">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon" aria-label="Menu">
            <mat-icon>menu</mat-icon>
            <mat-menu #menu="matMenu">
                <button [routerLink]="['/transmitir']" mat-menu-item>
                    <mat-icon>cloud_upload</mat-icon>
                    <span>Transmitir</span>
                </button>
                <button [routerLink]="['/acompanhar-transmissoes']" mat-menu-item>
                    <mat-icon>lists</mat-icon>
                    <span>Consultar Transmissões</span>
                </button>
            </mat-menu>
        </button>
        <span>Hermes</span>
        <span class="spacer"></span>

        <span><b class="d-none d-sm-inline">Título:</b> {{tituloUsuario}}</span>
        <button mat-icon-button class="d-none d-sm-block example-icon" aria-label="Usuário">
            <mat-icon>person</mat-icon>
        </button>
        <button mat-icon-button (click)="logout()" class="example-icon" aria-label="Sair">
            <mat-icon>logout</mat-icon>
        </button>
    </mat-toolbar>
</p>