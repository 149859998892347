<h2 mat-dialog-title>Detalhe(s) do(s) Erro(s)</h2>
<mat-dialog-content class="mat-typography">

    <ul>
        @for (mensagem of data.mensagens; track mensagem) {
        <li>{{ mensagem }}</li>
        }
    </ul>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Fechar</button>
</mat-dialog-actions>