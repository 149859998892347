import { Component, OnInit } from '@angular/core';
import { DominioService } from '@app/comum/servicos/dominio.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-rodape',
  standalone: true,
  templateUrl: './rodape.component.html',
  styleUrl: './rodape.component.css'
})
export class RodapeComponent implements OnInit {

  versaoAPI: string;

  constructor(private dominioService: DominioService){}

  ngOnInit(): void {
    this.dominioService.consultarVersaoAPI().subscribe( versao => {this.versaoAPI = versao})
  }

  get versaoUI(){
    return environment.appVersion;
  }

}
