{
  "name": "hermes-frontend",
  "version": "24.5.4-SNAPSHOT",
  "scripts": {
    "ng": "ng",
    "start": "node ./replace.build-local.js && ng serve --port 4200 --proxy-config proxy.conf.json",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:hermes-frontend": "node dist/hermes-frontend/server/server.mjs",
    "release-local": "node ./replace.build-local.js && node --max-old-space-size=8192 --optimize-for-size ./node_modules/.bin/ng build --configuration=local --verbose",
    "release-dsv": "node ./replace.build-dsv.js && node --max-old-space-size=8192 --optimize-for-size ./node_modules/.bin/ng build --configuration=dsv --verbose",
    "release-hmg": "node ./replace.build-hmg.js && node --max-old-space-size=8192 --optimize-for-size ./node_modules/.bin/ng build --configuration=hmg --verbose",
    "release-prod": "node ./replace.build.js && node --max-old-space-size=8192 --optimize-for-size ./node_modules/.bin/ng build --configuration=production --verbose"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.0.3",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/material": "^17.0.3",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/platform-server": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@angular/ssr": "^17.0.6",
    "bootstrap": "^5.3.2",
    "express": "^4.18.2",
    "jspdf": "^2.5.1",
    "jspdf-autotable": "^3.8.2",
    "keycloak-angular": "^15.2.1",
    "keycloak-js": "^24.0.1",
    "moment": "^2.29.4",
    "ngx-spinner": "^16.0.2",
    "ngx-toastr": "^18.0.0",
    "replace-in-file": "^7.1.0",
    "rxjs": "~7.8.0",
    "sinaps-cli": "^1.0.3",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.6",
    "@angular/cli": "^17.0.6",
    "@angular/compiler-cli": "^17.0.0",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-firefox-launcher": "^2.1.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}