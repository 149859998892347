<div>
  <div class="je-topo" fxlayout="row" ng-reflect-layout="row"
    style="flex-direction: row; box-sizing: border-box; display: flex;">
    <div class="je-conter">
      <div class="je-row">
        <div class="tse-link">
          <a target="_blank" href="http://www.tse.jus.br" title="Visitar Tribunal Superior Eleitoral"
            class="tse-link-item">Tribunal Superior Eleitoral</a>
        </div>
        <div class="je-link">
          <a target="_blank" href="http://www.justicaeleitoral.jus.br" title="Justiça Eleitoral"
            class="je-link-item">www.justicaeleitoral.jus.br</a>
        </div>
        <div class="acessibilidade-link">
          <a target="_blank" href="http://www.tse.jus.br/acessibilidades-tse" title="Link para acessibilidade"
            class="acessibilidade-link-item">Acessibilidade</a>
        </div>
      </div>
    </div>
  </div>
  <app-menu></app-menu>
</div>