export const MENSAGENS_SUCESSO = {
   MSGS001: "Transmiss\u00e3o conclu\u00edda com sucesso.",
}

export const MENSAGENS_INFO = {
   MSGI001: "Aguarde ou pare a transmiss\u00e3o. Processando...",
}

export const MENSAGENS_ALERTA = {
   MSGA001: "Transmiss\u00e3o incompleta.",
   MSGA002: "Selecione uma pasta v\u00e1lida",
   MSGA003: "Transmiss\u00e3o encerrado pelo usu\u00e1rio ou ocorreu algum erro.",
   MSGA004: "H\u00e1 arquivos com erro na transmiss\u00e3o. Caso queira retransmitir somente esses arquivos, clique no bot\u00e3o 'Retransmitir somente arquivos com erro'",
   MSGA005: "Pasta informada possui arquivos com tamanho maior do que o permitido (2MB). Selecione uma pasta com arquivos v\u00e1lidos.",
   MSGA006: "Pasta informada n\u00e3o possui arquivos de urna v\u00e1lidos. Selecione uma pasta com arquivos v\u00e1lidos.",
}

export const MSG_GENERICA_SUBSTITUICAO = 'MSG_REPLACE';

export const MENSAGENS_ERRO = {
   MSGE001: 'Erro durante a transmiss\u00e3o.',
   MSGE002: "Transmiss\u00e3o inv\u00e1lida.",
}







