<div class="conteudo-form">
  <form [formGroup]="form">

    <mat-progress-bar mode="determinate" [value]="progresso"></mat-progress-bar>
  
    <ngx-spinner bdColor="rgba(51,51,51,0.5)" size="medium" color="#fff">
      <div>
        <div class="col-md-12">
          <p class="alinhar_centro" style="color: white"> <b>"Aguarde ou pare a transmiss&atilde;o. Processando..."</b></p>
        </div>
        <div class="col-md-12">
          <mat-progress-bar mode="determinate" [value]="progresso"></mat-progress-bar>
        </div>
        <div class="alinhar_centro col-md-12">
          <button mat-raised-button color="warn" aria-label="Encerrar" (click)="pararTransmissao()">
            <i class="material-icons">
              cancel
            </i>
            Encerrar
          </button>
        </div>
      </div>
    </ngx-spinner>

    <div class="area-upload">

      <label for="upload-file" class="label-upload">
        <div class="texto">Clique para selecionar uma pasta</div>
        <i class="material-icons md-70">
          cloud_upload
        </i>
        <div>Pasta selecionada: <b>{{pastaSelecionada ? pastaSelecionada : 'Nenhuma'}}</b></div>
        <div>Quantidade de arquivos selecionados: <b>{{quantidadeArquivosSelecionados}}</b></div>
      </label>

      <input id="upload-file" formControlName="arquivos" type="file" (change)="selecionarArquivos($event)" allowdirs
        directory webkitdirectory multiple>

      <div class="actions">

        @if (temArquivosComErro) {
        <button class="me-2" mat-raised-button color="warn" aria-label="Retransmitir" (click)="lerArquivo(true)">
          <i class="material-icons">
            file_upload
          </i>
          Retransmitir somente arquivos com erro
        </button>
        }

        <button id="botao-transmitir" mat-raised-button color="primary" aria-label="Transmitir" (click)="lerArquivo()">
          <i class="material-icons">
            file_upload
          </i>
          Transmitir
        </button>
      </div>
    </div>

  </form>

    <mat-card *ngIf="consolidacao.length > 0" class="w-100 card-tabela mt-3 border border-success align-self-center">
        <div class="div_tabela m-0 p-0 table-responsive border-success">
            <table mat-table [dataSource]="consolidacao" class="table table-success table-hover table-striped p-0 m-0 align-middle">

                <tr id="header_tabela" class="align-middle" mat-header-row *matHeaderRowDef="colunasExibidas"></tr>
                <tr mat-row *matRowDef="let row; columns: colunasExibidas;"></tr>


                <ng-container matColumnDef="tipo">
                    <th class="ps-4 pe-0 " mat-header-cell *matHeaderCellDef> <mat-icon>info</mat-icon> </th>
                    <td class="" mat-cell *matCellDef="let arquivo"> {{TipoArquivoUrna[arquivo.nome].descricao}} </td>
                </ng-container>
                <ng-container matColumnDef="selecionado">
                    <th mat-header-cell *matHeaderCellDef> Enviados</th>
                    <td mat-cell *matCellDef="let arquivo"> {{arquivo.enviados}} </td>
                </ng-container>
                <ng-container matColumnDef="sucesso">
                    <th  mat-header-cell *matHeaderCellDef> Sucesso </th>
                    <td  mat-cell *matCellDef="let arquivo"> {{arquivo.sucesso}} </td>
                </ng-container>
                <ng-container matColumnDef="erro">
                    <th  mat-header-cell *matHeaderCellDef> Erro </th>
                    <td  mat-cell *matCellDef="let arquivo"> 
                    {{arquivo.erro}}
                      @if (arquivo.erro > 0) {
                        <mat-icon color="warn" class="align-middle" aria-label="Detalhes" (click)="apresentarDetalhesErros(arquivo.detalheErros)">info_outline</mat-icon>
                      }
                    </td>
                </ng-container>
            </table>

        </div>
    </mat-card>

    <div class="conteudo-caixa">
      <div class="conteudo-mensagens" [hidden]="!apresentarMensagens">
        <template #mensagens></template>
      </div>
    </div>

</div>