import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "@env/environment";
import {Paginador} from "@comum/modelo/paginador";
import {Pagina} from "@comum/modelo/pagina";
import {Transmissao} from "@comum/modelo/transmissao";

@Injectable({
  providedIn: 'root'
})
export class RelatorioService {

  baseUrlRelatorios = environment.baseUrl + 'relatorios/';

  constructor(private  http: HttpClient) { }

  public relatorioPdf(pleito:string, municipio:string, zona:string, secao:string, arquivosComErro:boolean, paginador:Paginador){
    let params = this.getParamsRelatorio(pleito, municipio,zona, secao, arquivosComErro, paginador);
    return this.http.get<Pagina<Transmissao>>(this.baseUrlRelatorios+"pdf", {params});
  }

  public relatorioCsv(pleito:string, municipio:string, zona:string, secao:string, arquivosComErro:boolean, paginador:Paginador){
    let params = this.getParamsRelatorio(pleito, municipio,zona, secao, arquivosComErro, paginador);
    return this.http.get(this.baseUrlRelatorios+"csv", {params, responseType:'text'});
  }

  private getParamsRelatorio(pleito:string, municipio:string, zona:string, secao:string, arquivosComErro:boolean, paginador:Paginador){
    let params = new HttpParams();
    params = params.append('pleito', pleito??'').append('sgUe', municipio??'').append('zona', zona??'').append('secao', secao??'')
        .append('page', paginador.pageIndex??'')
        .append('size', paginador.pageSize??'')
        .append('arquivosComErro', arquivosComErro)
        .append('time', new Date().toISOString());
    if(paginador.active){
      params = params.append('sort',paginador.active+','+paginador.direction??'');
    }
    return params;
  }

}
