import { APP_INITIALIZER, ApplicationConfig, Provider } from '@angular/core';
import { provideRouter } from '@angular/router';

import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { provideToastr } from 'ngx-toastr';
import { routes } from './app.routes';

declare var require: any;

const Keycloak = typeof window !== 'undefined' ? import('keycloak-js') : null;

export function initializeKeycloak(keycloak: KeycloakService) {
  if (Keycloak !== null) {
    return () =>
      keycloak.init(environment.keycloak);
  } else {
    return () => {
      return new Promise<Boolean>((resolve, reject) => {
        resolve(true);
      });
    };
  }
}

// Provider for Keycloak Bearer Interceptor
const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

// Provider for Keycloak Initialization
const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService]
}

export const appConfig: ApplicationConfig = {
  providers: [
    KeycloakInitializerProvider, 
    KeycloakBearerInterceptorProvider, 
    KeycloakService, 
    provideRouter(routes), 
    provideClientHydration(), 
    provideAnimations(), 
    provideToastr(), 
    provideHttpClient(withFetch(), withInterceptorsFromDi())
    
  ], 
};
