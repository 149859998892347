
<mat-card class="m-3">
  <mat-card-header>
  <h1>Consultar Transmissões</h1>
</mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="row">
      <div class="row g-1">
        <mat-form-field class="col-12 col-sm-6 col-md-4 col-lg-2">
          <mat-label>Pleito</mat-label>
          <mat-select formControlName="pleito">
            @for (pleito of pleitos; track pleito) {
            <mat-option [value]="pleito">{{pleito}}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-12 col-sm-6 col-md-4 col-lg-2">
          <mat-label>Município</mat-label>
          <mat-select formControlName="municipio">
            @for (municipio of municipios; track municipio) {
            <mat-option [value]="municipio.sgUe">{{municipio.nome}}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-12 col-sm-6 col-md-4 col-lg-2">
          <mat-label>Zona</mat-label>
          <mat-select formControlName="zona">
            @for (zona of zonas; track zona) {
            <mat-option [value]="zona">{{zona}}</mat-option>
            }
          </mat-select>
        </mat-form-field>


        <mat-form-field class="col-12 col-sm-6 col-md-4 col-lg-2">
          <mat-label>Seção</mat-label>
          <mat-select formControlName="secao">
            @for (secao of secoes; track secao) {
            <mat-option [value]="secao">{{secao}}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div class="col-2 col-lg-1 d-flex gap-2 mt-2 ms-3">
          <button (click)="limparForm()" mat-mini-fab color="primary" aria-label="Limpar seleção">
            <mat-icon>delete</mat-icon>
          </button>
          <button (click)="pesquisar()" mat-mini-fab color="primary" aria-label="Pesquisar">
            <mat-icon>search</mat-icon>
          </button>
        </div>



      </div>
      <mat-checkbox formControlName="apresentarApenasArquivosComErro" class="mt-3">
        Apresentar apenas arquivos com erro
      </mat-checkbox>


    </form>
  </mat-card-content>
</mat-card>
@if(pagina.totalElements>0){

  <mat-card class="m-3 border border-success">
    <div id="resultado" class="div_tabela m-0 p-0 table-responsive border-success border-bottom">
      <table id="tabela" (matSortChange)="paginar($event)" matSort mat-table [dataSource]="pagina.content" class="table table-success table-hover table-striped p-0 m-0 align-middle">
  
        <tr id="header_tabela" class="align-middle" mat-header-row *matHeaderRowDef="colunasExibidas"></tr>
        <tr mat-row *matRowDef="let row; columns: colunasExibidas;"></tr>
  
  
        <ng-container matColumnDef="siglaMunicipio">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> Cód. Município </th>
          <td mat-cell *matCellDef="let arquivo"> {{arquivo.siglaMunicipio}} </td>
        </ng-container>
  
  
        <ng-container matColumnDef="municipio">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> Município </th>
          <td mat-cell *matCellDef="let arquivo"> {{arquivo.municipio}} </td>
        </ng-container>
  
  
        <ng-container matColumnDef="zona">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> Zona </th>
          <td mat-cell *matCellDef="let arquivo"> {{arquivo.zona}} </td>
        </ng-container>   
  
        <ng-container matColumnDef="secao">
          <th class="pe-5" mat-sort-header mat-header-cell *matHeaderCellDef> Seção </th>
          <td mat-cell *matCellDef="let arquivo"> {{arquivo.secao}} </td>
        </ng-container>
  
  
        <ng-container matColumnDef="bu">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> BU </th>
          <td mat-cell *matCellDef="let arquivo"> @if (arquivo.bu == 'T') {
            <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.bu == 'E'){
            <mat-icon class="text-danger">close</mat-icon>
            }@else{
              <mat-icon class="text-secondary">unpublished</mat-icon>
          }
          </td>
        </ng-container>
  
  
        <ng-container matColumnDef="rdv">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> RDV </th>
            <td mat-cell *matCellDef="let arquivo"> @if (arquivo.rdv == 'T') {
                <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.rdv == 'E'){
                <mat-icon class="text-danger">close</mat-icon>
            }@else{
                <mat-icon class="text-secondary">unpublished</mat-icon>
            }
            </td>
        </ng-container>
  
        <ng-container matColumnDef="imgBu">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> ImgBU </th>
            <td mat-cell *matCellDef="let arquivo"> @if (arquivo.imgBu == 'T') {
                <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.imgBu == 'E'){
                <mat-icon class="text-danger">close</mat-icon>
            }@else{
                <mat-icon class="text-secondary">unpublished</mat-icon>
            }
            </td>
        </ng-container>
  
        <ng-container matColumnDef="jufa">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> JUFA </th>
            <td mat-cell *matCellDef="let arquivo"> @if (arquivo.jufa == 'T') {
                <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.jufa == 'E'){
                <mat-icon class="text-danger">close</mat-icon>
            }@else{
                <mat-icon class="text-secondary">unpublished</mat-icon>
            }
            </td>
        </ng-container>
  
        <ng-container matColumnDef="log">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> Log </th>
            <td mat-cell *matCellDef="let arquivo"> @if (arquivo.log == 'T') {
                <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.log == 'E'){
                <mat-icon class="text-danger">close</mat-icon>
            }@else{
                <mat-icon class="text-secondary">unpublished</mat-icon>
            }
            </td>
        </ng-container>
  
  
        <ng-container matColumnDef="assinatura">
          <th class="me-0" mat-sort-header mat-header-cell *matHeaderCellDef> Assinatura </th>
            <td mat-cell *matCellDef="let arquivo"> @if (arquivo.assinatura == 'T') {
                <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.assinatura == 'E'){
                <mat-icon class="text-danger">close</mat-icon>
            }@else{
                <mat-icon class="text-secondary">unpublished</mat-icon>
            }
            </td>
        </ng-container>
  
        <ng-container matColumnDef="hash">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> Hash </th>
            <td mat-cell *matCellDef="let arquivo"> @if (arquivo.hash == 'T') {
                <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.hash == 'E'){
                <mat-icon class="text-danger">close</mat-icon>
            }@else{
                <mat-icon class="text-secondary">unpublished</mat-icon>
            }
            </td>
        </ng-container>
  
        <ng-container matColumnDef="ver">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> VER </th>
            <td mat-cell *matCellDef="let arquivo"> @if (arquivo.ver == 'T') {
                <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.ver == 'E'){
                <mat-icon class="text-danger">close</mat-icon>
            }@else{
                <mat-icon class="text-secondary">unpublished</mat-icon>
            }
            </td>
        </ng-container>
  
        <ng-container matColumnDef="info">
          <th mat-sort-header mat-header-cell *matHeaderCellDef> Info </th>
            <td mat-cell *matCellDef="let arquivo"> @if (arquivo.info == 'T') {
                <mat-icon class="mat-primary">check_circle</mat-icon>
            }@else if(arquivo.info == 'E'){
                <mat-icon class="text-danger">close</mat-icon>
            }@else{
                <mat-icon class="text-secondary">unpublished</mat-icon>
            }
            </td>
        </ng-container>
      </table>
  
    </div>
    <div class="d-flex gap-2 mt-3 m-2 justify-content-between">
      <div class="d-inline-flex gap-2">
        <button (click)="getPdf()" mat-mini-fab color="primary" aria-label="Limpar seleção">
          <mat-icon>picture_as_pdf</mat-icon>
        </button>
        <button (click)="getCsv()" mat-mini-fab color="primary" aria-label="Pesquisar">
          CSV
        </button>
      </div>
      <mat-paginator [pageIndex]="paginador.pageIndex" (page)="paginar($event)" [length]="pagina.totalElements" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" aria-label="Selecione a página">
      </mat-paginator>
    </div>
  
  </mat-card>
}