import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Transmissao } from '../modelo/transmissao';
import { Pagina } from '../modelo/pagina';
import { Paginador } from '../modelo/paginador';


@Injectable({
  providedIn: 'root'
})
export class AcompanharTransmissaoService {


  baseUrlArquivos = environment.baseUrl + 'arquivo-urna/';

  constructor(private http: HttpClient) { }


  public consultarTransmissoes(pleito:string, municipio:string, zona:string, secao:string, arquivosComErro:boolean, paginador:Paginador){

    let params = new HttpParams();
    params = params.append('pleito', pleito??'').append('sgUe', municipio??'').append('zona', zona??'').append('secao', secao??'')
      .append('page', paginador.pageIndex??'')
      .append('size', paginador.pageSize??'')
      .append('arquivosComErro', arquivosComErro)
        .append('time', new Date().toISOString());
      if(paginador.active){
        params = params.append('sort',paginador.active+','+paginador.direction??'');
      }
      
    return this.http.get<Pagina<Transmissao>>(this.baseUrlArquivos+"consultar-transmissoes", {params});
  }




}
