import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class PaginatorIntl extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Itens por página';
  override nextPageLabel     = 'Próxima página';
  override previousPageLabel = 'Página anterior';
  override getRangeLabel = (page, pageSize, length)=>length + ' registros - Página ' + (page+1) + ' de ' + (Math.ceil(length/pageSize));

}