import { Injectable } from '@angular/core';
import { MSG_GENERICA_SUBSTITUICAO } from '@app/comum/modelo/mensagens';
import { ToastrService } from 'ngx-toastr';

const CONFIGURACAO_PADRAO = { disableTimeOut: true };

@Injectable({
  providedIn: 'root'
})
export class MensagemService {

  constructor(private toastr: ToastrService) { }

  mensagem(mensagem: string, tipoMensagem: string){
    if(tipoMensagem == "MSGS"){
     return this.sucesso(tipoMensagem, mensagem);
    }

    if(tipoMensagem == "MSGA"){
      return this.aviso(tipoMensagem, mensagem);
    }

    if(tipoMensagem == "MSGE"){
      return this.erro(tipoMensagem, mensagem);
    }
    return this.info(tipoMensagem, mensagem);
  }

  sucesso(codigo: string, mensagem: string) {
    this.toastr.success(codigo, mensagem, CONFIGURACAO_PADRAO);
  }

  erro(codigo: string, mensagem: string, erro?: any) {
    if(erro){
      mensagem = mensagem.replace(MSG_GENERICA_SUBSTITUICAO, erro.mensagem);
    }
    this.toastr.error(codigo, mensagem, CONFIGURACAO_PADRAO);
  }

  aviso(codigo: string, mensagem: string) {
    this.toastr.warning(codigo, mensagem, CONFIGURACAO_PADRAO);
  }

  info(codigo: string, mensagem: string) {
    this.toastr.info(codigo, mensagem, CONFIGURACAO_PADRAO);
  }

  limparMensagens() {
    this.toastr.clear();
  }

}
