import { Component, Input } from '@angular/core';
import { TipoMensagem } from '@app/comum/modelo/enum/tipo-mensagem';

const IMAGEM_SVG_ERRO_ALERTA = '#exclamation-triangle-fill';
const IMAGEM_SVG_SUCCESS = '#check-circle-fill';
const IMAGEM_SVG_INFO = '#info-fill';

const CSS = 'alert d-flex align-items-center ';
const CSS_ERRO = CSS + ' alert-danger';
const CSS_ALERTA = CSS + ' alert-warning ';
const CSS_SUCCESS = CSS + ' alert-success';
const CSS_INFO = CSS + ' alert-primary';

@Component({
  selector: 'app-mensagem',
  standalone: true,
  imports: [],
  templateUrl: './mensagem.component.html',
  styleUrl: './mensagem.component.css'
})
export class MensagemComponent {
  @Input() mensagem: string;
  @Input() css: string;
  @Input() imagemSvg: string;

  constructor() { }

  ngOnInit() {
  }

  set tipo(tipo: TipoMensagem){
    switch(tipo){
      case TipoMensagem.ERRO: {
        this.css = CSS_ERRO;
        this.imagemSvg = IMAGEM_SVG_ERRO_ALERTA;
        break; 
      } 
      case TipoMensagem.SUCESSO: {
        this.css = CSS_SUCCESS;
        this.imagemSvg = IMAGEM_SVG_SUCCESS;
        break;
      } 
      case TipoMensagem.ALERTA: {
        this.css = CSS_ALERTA;
        this.imagemSvg = IMAGEM_SVG_ERRO_ALERTA;
        break;
      } 
      default: { 
        this.css = CSS_INFO;
        this.imagemSvg = IMAGEM_SVG_INFO;
        break; 
     } 
    }
  }
}
