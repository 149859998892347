import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Municipio } from '../modelo/municipio';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DominioService {

  baseUrlDominio = environment.baseUrl + 'dominio/';

  baseUrlInfo = environment.baseUrl + 'info/';

  constructor(private http: HttpClient) { }

  buscarFaseBanco(): string /*Observable<Resposta>*/ {
    // return this.http.get<Resposta>(this.baseUrlDominio + 'fase-banco');
    return "s";
  }

  public consultarPleitos() {
    return this.http.get<Number[]>(this.baseUrlDominio + "consultar-pleitos");
  }

  public consultarMunicipiosPorPleito(pleito: string) {
    let params = new HttpParams();
    params = params.append('pleito', pleito);
    return this.http.get<Municipio[]>(this.baseUrlDominio + "consultar-municipios-por-pleito", { params });
  }

  public consultarZonasPorPleitoMunicipio(pleito: string, municipio: string) {
    let params = new HttpParams();
    params = params.append('pleito', pleito).append('municipio', municipio);
    return this.http.get<Number[]>(this.baseUrlDominio + "consultar-zonas-por-pleito-municipio", { params });
  }

  public consultarSecoesPorPleitoMunicipioZona(pleito: string, municipio: string, zona: string) {
    let params = new HttpParams();
    params = params.append('pleito', pleito).append('municipio', municipio).append('zona', zona);
    return this.http.get<Number[]>(this.baseUrlDominio + "consultar-secoes-por-pleito-municipio-zona", { params });
  }

  public consultarVersaoAPI() {
    return this.http.get<string>(this.baseUrlInfo + "versao");
  }

}
