import { NgIf } from "@angular/common";
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCard } from "@angular/material/card";
import { MatIcon } from "@angular/material/icon";
import { MatPaginator } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from "@angular/material/table";
import { MensagemComponent } from "@app/comum/componentes/mensagem/mensagem.component";
import { TipoMensagem } from "@app/comum/modelo/enum/tipo-mensagem";
import {
  MENSAGENS_ALERTA,
  MENSAGENS_ERRO,
  MENSAGENS_SUCESSO,
} from "@app/comum/modelo/mensagens";
import { Resposta } from "@app/comum/modelo/resposta";
import { TipoArquivoUrna } from "@app/comum/modelo/tipo-arquivo-urna";
import { DominioService } from "@app/comum/servicos/dominio.service";
import { MensagemService } from "@app/comum/servicos/mensagem.service";
import { environment } from "@env/environment";
import moment from "moment";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import {
  Observable,
  catchError,
  delay,
  from,
  mergeMap,
  of,
  retry,
  takeWhile,
  timeout,
} from "rxjs";
import { TransmissaoService } from "./transmissao.service";
import { ApresentacaoErroDialogComponent } from "./apresentacao-erro-dialog/apresentacao-erro-dialog.component";
import { MatDialog } from "@angular/material/dialog";

const SEPARADOR_PASTA = "/";

@Component({
  selector: "app-transmissao",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MensagemComponent,
    MatCard,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatSort,
    MatSortHeader,
    MatTable,
    NgIf,
  ],
  templateUrl: "./transmissao.component.html",
  styleUrl: "./transmissao.component.css",
})
export class TransmissaoComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  @ViewChild("mensagens", { read: ViewContainerRef })
  container: ViewContainerRef;

  form: FormGroup;
  arquivos = new FormControl("", Validators.required);
  selecionado: FileList;
  pastaSelecionada: string = "";
  quantidadeArquivosSelecionados: number = 0;
  temArquivosComErro: boolean = false;
  transmissaoEmAndamento: boolean = false;
  somenteArquivosComErro: boolean = false;
  mostrarProgresso = false;
  progresso: number = 0;
  inscricoesRequisicoes;
  arquivosPorSecao: Map<string, File[]>;
  quantidadesArquivosEnviados: number;
  respostaGeral;
  dataHoraInicioTransmissao;
  temMensagensFeedback: boolean = false;
  faseBanco: string;
  colunasExibidas = ["tipo", "selecionado", "sucesso", "erro"];
  consolidacao = [];
  protected readonly TipoArquivoUrna = TipoArquivoUrna;
  apresentarMensagens: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private mensagemService: MensagemService,
    private spinner: NgxSpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
    private transmissaoService: TransmissaoService,
    private dominioService: DominioService,
    private dialog: MatDialog
  ) {}

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    if (this.inscricoesRequisicoes) {
      this.inscricoesRequisicoes.unsubscribe();
    }
    this.pararTransmissaoSemAlerta();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      arquivos: this.arquivos,
    });
    this.faseBanco = this.dominioService.buscarFaseBanco();
  }

  limparMensagens(): any {
    this.container.clear();
    this.mensagemService.limparMensagens();
    this.changeDetectorRef.detectChanges();
    this.temMensagensFeedback = false;
  }

  public apresentarDetalhesErros(detalhesErros: []){
    this.dialog.open(ApresentacaoErroDialogComponent, {
      data: {
        mensagens: detalhesErros,
      },
    });
  }

  async lerArquivo(somenteArquivosComErro?: boolean) {
    this.apresentarMensagens = false;
    this.temArquivosComErro = false;
    this.somenteArquivosComErro = somenteArquivosComErro;
    this.transmissaoEmAndamento = true;
    this.progresso = 0;
    this.mostrarProgresso = true;
    this.spinner.show();
    this.limparMensagens();
    this.upload(this.selecionado);
  }

  pararTransmissaoSemAlerta() {
    this.spinner.hide();
    this.transmissaoEmAndamento = false;
    this.mostrarProgresso = false;
  }

  pararTransmissao() {
    this.pararTransmissaoSemAlerta();
    this.mensagemService.aviso("MSGA003", MENSAGENS_ALERTA.MSGA003);
  }

  recuperarNomesArquivosComErro(): string[] {
    if (
      this.respostaGeral &&
      this.respostaGeral.nomesArquivosComErro &&
      this.respostaGeral.nomesArquivosComErro.length > 0
    ) {
      return this.respostaGeral.nomesArquivosComErro;
    }
    return null;
  }

  mesclarRespostas(resposta) {
    if (!resposta || this.verificarRespostaInvalidaOuVazia(resposta)) {
      console.log(
        "Erro ao mesclar respostas. " +
          (resposta ? JSON.stringify(resposta) : "")
      );
      return;
    }
    if (!this.respostaGeral) {
      this.respostaGeral = structuredClone(resposta);
    } else {
      this.respostaGeral.quantidadePacotesTransmitidos +=
        resposta.quantidadePacotesTransmitidos;
      this.respostaGeral.quantidadePacotesComErro +=
        resposta.quantidadePacotesComErro;
      this.respostaGeral.quantidadeArquivosComErro +=
        resposta.quantidadeArquivosComErro;
      this.respostaGeral.quantidadePacotesRecebidos +=
        resposta.quantidadePacotesRecebidos;
      this.respostaGeral.quantidadeArquivosRecebidos +=
        resposta.quantidadeArquivosRecebidos;

      Object.entries(resposta.mapaArquivosConsolidados).forEach(
        (entry: any) => {
          this.respostaGeral.mapaArquivosConsolidados[entry[0]].enviados +=
            entry[1].enviados;
          this.respostaGeral.mapaArquivosConsolidados[entry[0]].sucesso +=
            entry[1].sucesso;
          this.respostaGeral.mapaArquivosConsolidados[entry[0]].erro +=
            entry[1].erro;
        }
      );
      
      resposta.nomesArquivosComErro.forEach((nomeArquivo) => {
        this.respostaGeral.nomesArquivosComErro.push(nomeArquivo);
      });

      Object.keys(resposta.arquivosRecebidos).forEach((tipo) => {
        if (!this.respostaGeral.arquivosRecebidos[tipo]) {
          this.respostaGeral.arquivosRecebidos[tipo] =
            resposta.arquivosRecebidos[tipo];
        } else {
          this.respostaGeral.arquivosRecebidos[tipo] +=
            resposta.arquivosRecebidos[tipo];
        }
      });
    }
  }

  verificarRespostaInvalidaOuVazia(resposta) {
    return (
      !resposta ||
      (!resposta.quantidadeArquivosComErro &&
        !resposta.quantidadeArquivosRecebidos &&
        !resposta.quantidadePacotesComErro &&
        !resposta.quantidadePacotesRecebidos &&
        !resposta.quantidadePacotesTransmitidos)
    );
  }

  private temArquivosComErroRespostaGeral() {
    return (
      this.respostaGeral &&
      this.respostaGeral.nomesArquivosComErro &&
      this.respostaGeral.nomesArquivosComErro.length > 0
    );
  }

  tratarResposta() {
    this.spinner.hide();

    if (!this.arquivosPorSecao || this.arquivosPorSecao.size === 0) {
      console.log("Nao foram listados arquivos.");
      return;
    }

    if (this.verificarRespostaInvalidaOuVazia(this.respostaGeral)) {
      console.log(
        "Erro ao tratar resposta consolidada. " +
          (this.respostaGeral ? JSON.stringify(this.respostaGeral) : "")
      );
      this.mensagemService.erro("MSGE002", MENSAGENS_ERRO.MSGE002);
      return;
    }

    if (!this.dataHoraInicioTransmissao) {
      this.dataHoraInicioTransmissao = new Date();
    }
    let tempoFinalEmMilissegundos =
      new Date().getTime() - this.dataHoraInicioTransmissao.getTime();
    let tempoFinalEmMinutos = Math.floor(
      (tempoFinalEmMilissegundos / 1000 / 60) << 0
    );
    let tempoFinalEmSegundos = Math.floor(
      (tempoFinalEmMilissegundos / 1000) % 60
    );

    this.criaMensagem(
      "In\u00edcio da transmiss\u00e3o: " +
        moment(this.dataHoraInicioTransmissao).format("DD/MM/YYYY HH:mm:ss") +
        "<br>Fim da transmiss\u00e3o: " +
        moment(new Date()).format("DD/MM/YYYY HH:mm:ss") +
        "<br>Tempo total: " +
        tempoFinalEmMinutos +
        "," +
        tempoFinalEmSegundos +
        " minuto(s)" +
        "<br>Total de arquivos enviados: " +
        this.quantidadesArquivosEnviados +
        "<br>Total de se\u00e7\u00f5es esperadas: " +
        this.arquivosPorSecao.size +
        "<br>Total de arquivos recebidos: " +
        this.respostaGeral.quantidadeArquivosRecebidos,
      TipoMensagem.INFO
    );

    this.apresentarMensagens = true;

    if (
      this.respostaGeral.quantidadePacotesTransmitidos === 0 &&
      this.respostaGeral.quantidadePacotesComErro > 0
    ) {
      this.mensagemService.erro("MSGE002", MENSAGENS_ERRO.MSGE002);
    } else if (
      this.respostaGeral.quantidadePacotesTransmitidos ===
        this.arquivosPorSecao.size &&
      this.respostaGeral.quantidadeArquivosComErro === 0
    ) {
      this.mensagemService.sucesso("MSGS001", MENSAGENS_SUCESSO.MSGS001);
    } else {
      this.mensagemService.aviso("MSGA001", MENSAGENS_ALERTA.MSGA001);
    }
    if (this.temArquivosComErroRespostaGeral()) {
      this.temArquivosComErro = true;
      this.mensagemService.aviso("MSGA004", MENSAGENS_ALERTA.MSGA004);
    }

    this.consolidacao = Object.entries(
      this.respostaGeral.mapaArquivosConsolidados
    )
      .map((entry: any) => {
        entry[1].nome = entry[0];
        return entry[1];
      })
      .sort((tipo1, tipo2) => tipo1.nome.localeCompare(tipo2.nome));
  }

  tamanhoArquivosValido(fileList: FileList): boolean {
    const requiredSize = 1024 * 1024 * 2;

    for (let i: number = 0; i < fileList.length; i++) {
      if (fileList[i].size > requiredSize) {
        console.log(fileList[i].name + ": " + fileList[i].size + " bytes");
        return false;
      }
    }

    return true;
  }

  async upload(fileList: FileList) {
    if (!fileList) {
      this.mensagemService.aviso("MSGA002", MENSAGENS_ALERTA.MSGA002);
      this.pararTransmissaoSemAlerta();
      return;
    }

    let nomesArquivosComErro = this.recuperarNomesArquivosComErro();
    this.dataHoraInicioTransmissao = new Date();
    this.arquivosPorSecao = this.transmissaoService.filtrarArquivos(
      fileList,
      this.somenteArquivosComErro,
      nomesArquivosComErro
    );

    if (this.arquivosPorSecao && this.arquivosPorSecao.size > 0) {
      this.quantidadesArquivosEnviados = 0;
      this.progresso = 0;
      this.mostrarProgresso = true;
      this.respostaGeral = null;
      let contadorRequisicoes = 1;
      let contadorRespostas = 0;

      let requisicoes: Observable<Resposta>[] = [];
      let arquivosEnviar: File[] = [];

      // cria a lista de requisicoes
      this.arquivosPorSecao.forEach((valor, chave) => {
        if (!this.transmissaoEmAndamento) {
          return;
        }

        let arquivos: File[] = Array.from(valor);
        this.quantidadesArquivosEnviados += arquivos.length;
        arquivos.forEach((arquivo) => arquivosEnviar.push(arquivo));
        if (
          contadorRequisicoes % environment.tamanhoLoteEnvio == 0 ||
          contadorRequisicoes >= this.arquivosPorSecao.size
        ) {
          requisicoes.push(
            this.transmissaoService.transmitirArquivos(arquivosEnviar).pipe(
              delay(environment.tempoEsperaEntreRequisicoesMilissegundos),
              timeout(environment.timeoutEnvioArquivosMilissegundos),
              retry(environment.quantidadeTentativasEnvio),
              catchError((error) => of(error))
            )
          );
          arquivosEnviar = [];
        }
        contadorRequisicoes++;
      });

      // executa a lista de requisicoes
      if (requisicoes && requisicoes.length > 0) {
        this.inscricoesRequisicoes = from(requisicoes)
          .pipe(
            mergeMap((req) => req, environment.quantidadeRequisicoesParalelas)
          )
          .pipe(takeWhile((value) => this.transmissaoEmAndamento))
          .subscribe({
            next: (data) => {
              this.mesclarRespostas(data);
              contadorRespostas++;
              this.progresso =
                (contadorRespostas * 100) /
                Math.ceil(
                  this.arquivosPorSecao.size / environment.tamanhoLoteEnvio
                );
            },
            error: (err) => {
              console.error("Erro ao enviar os arquivos. " + err, err);
            },
            complete: () => {
              this.tratarResposta();
            },
          });
      }
    } else {
      this.mensagemService.aviso("MSGA003", MENSAGENS_ALERTA.MSGA003);
      this.pararTransmissaoSemAlerta();
    }
  }

  private criarStringExpressaoRegular() {
    return (
      new String()
        //.concat('^(s|o|t)')
        .concat(this.faseBanco.charAt(0).toLowerCase())
        .concat("[0-9]{5}")
        .concat("(ac|al|ap|am|ba|ce|df|es|go|ma|mt|ms|mg|pa|pb|pr|pe|pi|rj|rn|rs|ro|rr|sc|sp|se|to|zz)")
        .concat("[0-9]{13}")
        .concat("-")
        .concat(TipoArquivoUrna.expressaoRegularArquivos())
        .concat("$")
    );
  }

  filtrarTipoArquivoUrna(selecionados: FileList): any {
    let arquivos = Array.from(selecionados);
    let expressaoRegularFiltro = this.criarStringExpressaoRegular();
    let re = new RegExp(expressaoRegularFiltro);

    return arquivos.filter((arquivo) => re.test(arquivo.name));
  }

  selecionarArquivos(event: any) {
    this.selecionado = this.filtrarTipoArquivoUrna(event.target.files);
    this.quantidadeArquivosSelecionados = this.selecionado.length;
    if (this.selecionado.length === 0) {
      this.pastaSelecionada = "";
      this.mensagemService.aviso("MSGA006", MENSAGENS_ALERTA.MSGA006);
    }
    if (this.temPastaSelecionada()) {
      if (!this.tamanhoArquivosValido(this.selecionado)) {
        this.quantidadeArquivosSelecionados = 0;
        this.mensagemService.aviso("MSGA005", MENSAGENS_ALERTA.MSGA005);
      } else {
        this.pastaSelecionada =
          (this.selecionado[0] as any).webkitRelativePath.split(
            SEPARADOR_PASTA
          )[0] + "/...";
      }
    }
  }

  private temPastaSelecionada() {
    return (
      this.selecionado &&
      this.selecionado.length > 0 &&
      (this.selecionado[0] as any).webkitRelativePath &&
      (this.selecionado[0] as any).webkitRelativePath.includes(SEPARADOR_PASTA)
    );
  }

  private criaMensagem(mensagem: string, tipo: TipoMensagem): void {
    let componentRef: ComponentRef<MensagemComponent> =
      this.container.createComponent(MensagemComponent, { index: 0 });
    componentRef.instance.mensagem =
      "<b>" + moment(new Date()).format("DD/MM/YYYY HH:mm:ss") + " </b><br/> " + mensagem;
    componentRef.instance.tipo = tipo;
    this.temMensagensFeedback = true;
  }
}
