import {Component, OnInit} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {PaginatorIntl} from '@app/comum/internationalization/PaginatorIntl';
import {Paginador} from '@app/comum/modelo/paginador';
import {DominioService} from '@app/comum/servicos/dominio.service';
import {Municipio} from '@app/comum/modelo/municipio';
import {AcompanharTransmissaoService} from '@app/comum/servicos/acompanhar-transmissao.service';
import {Transmissao} from '@app/comum/modelo/transmissao';
import {Pagina} from '@app/comum/modelo/pagina';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import autoTable, {CellDef} from "jspdf-autotable";
import {firstValueFrom} from "rxjs";
import {Constantes} from "@comum/modelo/constantes";
import {RelatorioService} from "@comum/servicos/relatorio.service";


const JUSTICA_ELEITORAL =  'Justi\u00e7a Eleitoral';
const RELATORIO = "Relat\u00F3rio de transmiss\u00E3o de arquivos";
const PLEITO = "Pleito: ";
const MUNICIPIO = "Munic\u00EDpio: ";
const ZONA = "Zona: ";
const SECAO = "Se\u00e7\u00e3o: ";
const NOME_ARQUIVO = "Relat\u00F3rio de transmissão de arquivos.pdf"
const COR_TSE = '#009688';
const COR_CINZA = '#636363';

@Component({
    selector: 'app-acompanhar-transmissao',
    standalone: true,
    imports: [FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatCardModule, MatIconModule, MatButtonModule,
        MatCheckboxModule, MatTableModule, MatSortModule, MatPaginatorModule, ReactiveFormsModule],
    providers: [{provide: MatPaginatorIntl, useClass: PaginatorIntl}],
    templateUrl: './acompanhar-transmissao.component.html',
    styleUrl: './acompanhar-transmissao.component.scss'
})
export class AcompanharTransmissaoComponent implements OnInit {


    private readonly headerRelatorioPdf = {'siglaMunicipio':'C\u00F3d. Munic\u00EDpio',
        'municipio': 'Munic\u00EDpio',
        'zona' : 'Zona',
        'secao' : 'Se\u00E7\u00E3o',
        'bu' : 'Bu',
        'rdv' : 'Rdv',
        'imgBu' : 'ImgBu',
        'jufa' : 'Jufa',
        'log' : 'Log',
        'assinatura' : 'Assinatura',
        'hash' : 'Hash',
        'ver': 'VER',
        'info' : 'Info'};

    readonly colunasExibidas = ['siglaMunicipio', 'municipio', 'zona', 'secao', 'bu', 'rdv', 'imgBu', 'jufa', 'log', 'assinatura',
        'hash', 'ver', 'info'];

    pleitos: Number[];
    municipios: Municipio[];
    zonas: Number[];
    secoes!: Number[];

    form!: FormGroup;
    pleito: FormControl = new FormControl();
    municipio = new FormControl();
    zona = new FormControl();
    secao = new FormControl();
    apresentarApenasArquivosComErro = new FormControl(false);
    paginador: Paginador = new Paginador();
    pagina: Pagina<Transmissao> = new Pagina<Transmissao>();
    dadosPesquisa: any = {};

    constructor(private dominioService: DominioService, private acompanharTransmissaoService: AcompanharTransmissaoService,
                private relatorioService:RelatorioService) {
    }

    ngOnInit(): void {
        this.carregarFormulario();
    }


    private carregarFormulario() {
        this.form = new FormGroup({
            'pleito': this.pleito,
            'municipio': this.municipio,
            'zona': this.zona,
            'secao': this.secao,
            'apresentarApenasArquivosComErro': this.apresentarApenasArquivosComErro
        });
        this.dominioService.consultarPleitos().subscribe(pleitos => this.pleitos = pleitos);
        this.pleito.valueChanges.subscribe(pleito => {
            this.limparMunicipios();
            if (pleito) {
                this.dominioService.consultarMunicipiosPorPleito(pleito).subscribe(municipios => this.municipios = municipios);
            }
        });
        this.municipio.valueChanges.subscribe(municipio => {
            this.limparZonas();
            if (municipio) {
                this.dominioService.consultarZonasPorPleitoMunicipio(this.pleito.value, municipio).subscribe(zonas => this.zonas = zonas);
            }
        });
        this.zona.valueChanges.subscribe(zona => {
            this.limparSecoes();
            if (zona) {
                this.dominioService.consultarSecoesPorPleitoMunicipioZona(this.pleito.value, this.municipio.value, zona).subscribe(secoes => this.secoes = secoes);
            }
        });
    }

    limparForm() {
        this.form.reset();
        this.apresentarApenasArquivosComErro.setValue(false);
    }

    limparMunicipios() {
        this.municipios = [];
        this.municipio.setValue('');
        this.limparZonas();
    }

    limparZonas() {
        this.zonas = [];
        this.zona.setValue('');
        this.limparSecoes();
    }

    limparSecoes() {
        this.secoes = [];
        this.secao.setValue('')
    }


    paginar(paginador: any) {
        Object.assign(this.paginador, paginador);
        this.consultarTransmissoes()
    }

    pesquisar() {
        this.dadosPesquisa = {
            pleito: this.pleito.value,
            municipio: this.municipio.value,
            municipioNome: this.municipios?.length > 0 ? this.municipios.filter(municipio => municipio.sgUe == this.municipio.value)[0]?.nome : '',
            zona: this.zona.value,
            secao: this.secao.value,
            arquivosComErro: this.apresentarApenasArquivosComErro.value
        }
        this.paginador.pageIndex = 0;
        this.consultarTransmissoes();
    }

    consultarTransmissoes() {
        console.log(this.dadosPesquisa)
        this.acompanharTransmissaoService.consultarTransmissoes(this.dadosPesquisa.pleito, this.dadosPesquisa.municipio,
            this.dadosPesquisa.zona, this.dadosPesquisa.secao, this.dadosPesquisa.arquivosComErro, this.paginador)
            .subscribe(pagina => {
                this.pagina = pagina
            });
    }


    public getCsv(){
        this.relatorioService.relatorioCsv(this.dadosPesquisa.pleito, this.dadosPesquisa.municipio,
            this.dadosPesquisa.zona, this.dadosPesquisa.secao, this.dadosPesquisa.arquivosComErro, this.paginador).subscribe(response => {

            const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = 'Relat\u00F3rio de Transmiss\u00E3o de Arquivos.csv';
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    }


    public async getPdf() {

        let pagina = await firstValueFrom(this.relatorioService.relatorioPdf(this.dadosPesquisa.pleito, this.dadosPesquisa.municipio,
            this.dadosPesquisa.zona, this.dadosPesquisa.secao, this.dadosPesquisa.arquivosComErro, this.paginador));

        let doc = new jsPDF('l', 'px', [850, 600]);
        let dataHora = new Date().toLocaleString('pt-BR').split(', ');

        autoTable(doc, {
            head:[this.headerRelatorioPdf],
            body:pagina.content.map(transmissao=>Object.entries(transmissao)).map(entry=>{
                let rowInput:any = {};
                entry.forEach(field => rowInput[field[0]] = field[1]);
                return rowInput;
            }),

            margin: {top: 80},
            headStyles: {
                fillColor: COR_TSE,
                minCellHeight: 25,
                valign: 'middle'
            },
            willDrawPage: data => {
                doc.addImage(Constantes.BRASAO_TSE, 'JPEG', 35, 15, 50, 50)
                doc.setFontSize(10);
                doc.setTextColor(COR_CINZA);
                let dados = []
                dados.push(JUSTICA_ELEITORAL);
                dados.push(RELATORIO);
                if (this.dadosPesquisa.pleito) dados.push(PLEITO.concat(this.dadosPesquisa.pleito));
                if (this.dadosPesquisa.municipio) dados.push(MUNICIPIO + this.dadosPesquisa.municipioNome)
                if (this.dadosPesquisa.zona) dados.push(ZONA + this.dadosPesquisa.zona);
                if (this.dadosPesquisa.secao) dados.push(SECAO + this.dadosPesquisa.secao);
                doc.text(dados, 90, 25)
                doc.text(dataHora, 815,25, {align:'right'})
            },
            didParseCell: data => {
                data.cell.styles.halign = 'center';
                if (data.cell.text.some(texto => texto == 'T' || texto == 'E' || texto == 'P')) {
                    data.cell.styles.textColor = data.cell.styles.fillColor;
                }
            },
            didDrawCell: (data) => {
                if (data.cell.text.some(text => text == 'T')) {
                    doc.addImage(Constantes.ICONE_OK, 'PNG', data.cell.x - 5 + (data.cell.width / 2), data.cell.y + 3, 10, 10);
                }
                if (data.cell.text.some(text => text == 'E')) {
                    doc.addImage(Constantes.ICONE_ERROR, 'PNG', data.cell.x - 8 + (data.cell.width / 2), data.cell.y + 1, 15, 15);
                }
                if (data.cell.text.some(text => text == 'P')) {
                    doc.addImage(Constantes.ICONE_PENDING, 'PNG', data.cell.x - 6 + (data.cell.width / 2), data.cell.y + 2, 12, 12);
                }
            }
        }),
            doc.save(NOME_ARQUIVO);
    }


}
